define("discourse/plugins/supporthub-header/discourse/templates/connectors/home-logo/supporthub", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href="/" title="Home page" class="logo">
    <img src="https://support.primero.org/assets/img/logo.png" alt="Primero">
  </a>
  <nav class="bg-white text-base rightBorder items-center self-stretch my-4 relative inset-0 flex flex-row justify-end flex-1">
        <ul class="primeroNavMenu flex-row flex items-center">
          <li class="my-2 mx-2">
            <a href="https://support.primero.org">HOME</a>
          </li>
          <li class="my-2 mx-2">
            <a href="https://support.primero.org/releases">RELEASES</a>
          </li>
          <li class="my-2 mx-2">
            <a href="https://support.primero.org/documentation">DOCUMENTATION</a>
          </li>
          <li class="my-2 mx-2">
            <a href="https://support.primero.org/support">SUPPORT</a>
          </li>
        </ul>
  </nav>
  
  */
  {
    "id": "/Bz8vv3N",
    "block": "[[[10,3],[14,6,\"/\"],[14,\"title\",\"Home page\"],[14,0,\"logo\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"https://support.primero.org/assets/img/logo.png\"],[14,\"alt\",\"Primero\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"nav\"],[14,0,\"bg-white text-base rightBorder items-center self-stretch my-4 relative inset-0 flex flex-row justify-end flex-1\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"primeroNavMenu flex-row flex items-center\"],[12],[1,\"\\n        \"],[10,\"li\"],[14,0,\"my-2 mx-2\"],[12],[1,\"\\n          \"],[10,3],[14,6,\"https://support.primero.org\"],[12],[1,\"HOME\"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[14,0,\"my-2 mx-2\"],[12],[1,\"\\n          \"],[10,3],[14,6,\"https://support.primero.org/releases\"],[12],[1,\"RELEASES\"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[14,0,\"my-2 mx-2\"],[12],[1,\"\\n          \"],[10,3],[14,6,\"https://support.primero.org/documentation\"],[12],[1,\"DOCUMENTATION\"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[14,0,\"my-2 mx-2\"],[12],[1,\"\\n          \"],[10,3],[14,6,\"https://support.primero.org/support\"],[12],[1,\"SUPPORT\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/supporthub-header/discourse/templates/connectors/home-logo/supporthub.hbs",
    "isStrictMode": false
  });
});